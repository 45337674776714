import type { ComponentProps } from "react";
import { Suspense } from "react";

import { Await } from "@remix-run/react";

import { Skeleton } from "../ui/skeleton";

export const SkeletonAwait = <T,>({
  resolve,
  children,
  forceSkeleton = false,
  skeleton,
  ...props
}: {
  skeleton?: JSX.Element;
  children: (resolved: T) => React.ReactNode;
  resolve: T | Promise<T>;
  forceSkeleton?: boolean;
} & Omit<ComponentProps<typeof Skeleton>, "children">) => {
  if (forceSkeleton) return skeleton ?? <Skeleton {...props} />;
  if (resolve instanceof Promise) {
    return (
      <Suspense fallback={skeleton ?? <Skeleton {...props} />}>
        <Await resolve={resolve}>{children}</Await>
      </Suspense>
    );
  }
  return typeof children === "function" ? children(resolve) : children;
};
